import styled from "styled-components"

type Props = {
  width?: number
}
export const StyledRequestForm = styled.div<Props>`
  --width: ${(Props) => (Props.width ? Props.width : 30)}rem;

  background-color: white;
  opacity: 0.95;
  border-radius: 2rem;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  height: max-content;

  @media (max-width: 815px) {
    width: calc(var(--width) - 7rem);
  }
  @media (min-width: 815px) {
    width: calc(var(--width) - 7rem);
  }
  @media (min-width: 1000px) {
    width: calc(var(--width) - 5rem);
  }

  @media (min-width: 1200px) {
    width: var(--width);
  }
`
