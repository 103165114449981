import styled from "styled-components";
import { theme } from "../../theme";

export const StyledFooter = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
  font-family: "Work Sans", sans-serif;
  font-weight: 150;
  font-size: 0.9rem;
  postioning: absolute;
  bottom: 0;
  width: 100vw;
  background-color: ${theme.colors.primary};
  height: 5rem;
  color: ${theme.colors.white};

  .footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: inherit;
    width: 90vw;
    margin-left: 5vw;

  }
 
  .contact {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
  }

  .icon {
    font-size: 1.5rem;

  }
  .icon-text {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  @media (max-width: 815px){

    .footer-container {
      flex-direction: column-reverse
    }
    .contact {
      flex-direction: column;
    }
    .icon-text{
      width: 50vw;
      justify-content: space-between;
      
    }
    .icon-text p {
      width: 40vw;
    }
    height: 7rem;
    
 }
  @media (min-width: 815px) {

@media (min-width: 1000px) {

}

@media (min-width: 1200px) {

}
  
`;
