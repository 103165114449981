import React from "react";
import { StyledCard, Header, Text } from "./styled/StyledCard";

interface Props {
  position: [string, string, string, string];
  header: string;
  text?: string;
}
export const Card: React.FC<Props> = ({ position, header, text }) => {
  return (
    <StyledCard pos={position}>
      <Header>{header}</Header>
      <Text>{text}</Text>
    </StyledCard>
  );
};
