import React from "react";
import { StyledImagePan } from "./styled/StyledImagePan";

interface Props {
  className?: string;
  image: string;
  direction: [string, string];
}

export const ImagePan: React.FC<Props> = ({ image, direction, children }) => {
  return (
    <StyledImagePan image={image} direction={direction}>
      <div className="bottom-left">{children}</div>
    </StyledImagePan>
  );
};
