import styled from 'styled-components'

interface Props {
  src: string
  height: string
  width: string
  main?: boolean
}

interface ContainerProps {
  height: string
  width: string
}

export const StyledImage = styled.img<Props>`
  height: ${(Props) => Props.height};
  width: ${(Props) => Props.width};
  background-image: ${(Props) => Props.src};
  display: block;
  object-fit: cover;
  object-position: top;
`
export const StyledContainer = styled.div<ContainerProps>`
  height: ${(Props) => Props.height};
  width: ${(Props) => Props.width};
  overflow: hidden;
  cursor: pointer;
  img {
    transition: transform 2s ease;
  }
  :hover img {
    transform: scale(1.2);
  }
`
