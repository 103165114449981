import styled from 'styled-components'

interface Props {
  pos: [string, string, string, string]
}

export const StyledCard = styled.div<Props>`
  @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');
  font-family: 'Rajdhani', sans-serif;

  display: flex;
  flex-wrap: wrap;
  align-content: center;
  width: 65vw;
  z-index: inherit;

  position: absolute;

  left: ${(Props) => Props.pos[0]};
  top: ${(Props) => Props.pos[1]};
  right: ${(Props) => Props.pos[2]};
  bottom: ${(Props) => Props.pos[3]};

  @media (max-width: 815px) {
    width: 80vw;
  }
`

export const Header = styled.div`
  font-size: 4vw;
  color: white;

  @media (max-width: 815px) {
    font-size: 8vw;
  }
`
export const Text = styled.div`
  font-size: 2vw;
  color: white;

  @media (max-width: 815px) {
    font-size: 3vw;
  }
`
