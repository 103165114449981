import React, { useState } from 'react'
import { StyledContainer, StyledImage } from './StyledImage'
import { Image } from './Image'
import { Box, SimpleGrid } from '@chakra-ui/react'

type Props = {
  images: string[]
  size: number
}

export const ImageGallery: React.FC<Props> = ({ images, size }) => {
  // const size = 120;
  const dHeight = size * 5
  const dWidth = size * 3
  const gSize = size / 20
  const tSize = (dHeight - 4 * gSize) / 5

  const displayHeight = `${dHeight}px`
  const displayWidth = `${dWidth}px`
  const thumbSize = `${tSize}px`
  const gap = `${gSize}px`
  const doubleGap = `${gSize * 2}px`

  const [selectedFirstImage, setSelectedFirstImage] = useState({
    index: 0,
    src: images[0],
  })
  const [selectedSecondImage, setSelectedSecondImage] = useState({
    index: 0,
    src: images[0],
  })

  const [showAnimation, setShowAnimation] = useState(false)

  const clickHandler = (src: string, index: number) => {
    if (showAnimation) {
      setSelectedFirstImage({
        src,
        index,
      })
    } else {
      setSelectedSecondImage({
        src,
        index,
      })
    }

    setShowAnimation(showAnimation ? false : true)
  }

  return (
    <Box width={`${dHeight}px`} overflow="clip">
      <SimpleGrid
        columns={2}
        spacingX={doubleGap}
        position="relative"
        width={`${dWidth * 2}px`}
        height={dHeight}
      >
        <Image
          selectedFirstImage={selectedFirstImage}
          selectedSecondImage={selectedSecondImage}
          displayHeight={displayHeight}
          displayWidth={displayWidth}
          showAnimation={showAnimation}
        />
        <SimpleGrid
          width={`${size * 2}px`}
          columns={2}
          spacingY={gap}
          style={{ zIndex: '5' }}
        >
          {images.map((image, index) => (
            <StyledContainer height={thumbSize} width={thumbSize}>
              <StyledImage
                onClick={() => clickHandler(image, index)}
                key={index}
                src={image}
                height={thumbSize}
                width={thumbSize}
              />
            </StyledContainer>
          ))}
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  )
}
