import React from 'react'
import { Card } from '../components/Card'
import { Summary } from '../components/Summary'
import { ImagePan } from '../components/ImagePan'
import { images } from '../theme'
import { ProjectCard } from '../components/ProjectCard'
// import { useGalleriesQuery } from '../generated/graphql'
import { Box } from '@chakra-ui/react'
import { galleries as galleriesData } from '../data'
import { Gallery } from '../types'

export const Home: React.FC = () => {
  // const { data } = useGalleriesQuery()
  const url = process.env.REACT_APP_API_HOST || ''

  const galleries: Gallery[] = galleriesData.map((gallery) => {
    return {
      header: gallery.header,
      images: gallery.images,
      description: gallery.description,
    }
  })
  // const galleries = data?.galleries?.data.map((gallery) => {
  // const galleries = galleriesData.map((gallery) => {
  // return {
  // id: gallery.id,
  // header: gallery.attributes?.label,
  // images: gallery.attributes?.images?.map((image) => {
  //   return url.concat(image?.image?.data?.attributes?.url || '')
  // }),
  // description: gallery.attributes?.description,
  //   }
  // })

  return (
    <Box>
      <ImagePan image={images.main.team} direction={['0', '100']}>
        <Card
          position={['10%', '', '', '3%']}
          header="MEET THE TEAM"
          text="IN THEIR FAMOUS TI PAINT YELLOW OVERALLS"
        />
      </ImagePan>
      <ImagePan
        className="bg"
        image={images.main.fleet}
        direction={['100', '0']}
      >
        <Card
          position={['', '1%', '10%', '']}
          header="THE FLEET "
          text="WITH OUR FLEET OF 6 VEHICLES WE ARE ABLE TO SERVE OUR CUSTOMERS FROM KEURBOOMS ALL THE WAY TO MOSSELBAY"
        />
      </ImagePan>
      <Summary />

      {galleries &&
        galleries.map((gallery, i) => {
          return (
            <ProjectCard
              key={i}
              header={gallery.header || ''}
              description={gallery.description}
              images={gallery.images || []}
              variant={!!((i + 1) % 2)}
            />
          )
        })}
    </Box>
  )
}
