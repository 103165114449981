import styled from 'styled-components'
import { theme } from '../../theme'

interface Props {
  open?: boolean
}

export const Layout = styled.div`
  width: 100vw;
  height: max-content;
  background-color: white;
  position: relative;
`

export const CloseMenuDiv = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  display: ${({ open }) => (open ? '' : 'none')};
  z-index: 4;
`

export const MenuButton = styled.i<Props>`
  font-size: 3rem;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0.5rem 2rem;
  color: ${({ open }) => (open ? 'white;' : theme.colors.primary)};
  transition: color 300ms;
  z-index: 12;

  .menu {
    height: 47.2px;
  }
  .light {
    color: white;
    transition: color 300ms;
  }
  @media (min-width: 815px) {
    display: none;
  }
`

export const MobileNav = styled.div<Props>`
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4rem 0.5rem;
  flex-direction: column;
  gap: 1.4rem;
  background-color: ${theme.colors.primary};
  transform: ${({ open }) => (open ? 'translateX(0%);' : 'translateX(100%);')};
  transition: transform 300ms;
  font-size: 1rem;
  z-index: 11;

  @media (min-width: 320px) {
    width: 50vw;
  }
  @media (min-width: 530px) {
    width: 30vw;
  }
  @media (min-width: 815px) {
    display: none;
  }

  a {
    text-decoration: none;
    width: 50vw;
    font-family: Arial-Black;
    color: white;
    transition: color 300ms;
  }
`

export const DesktopNav = styled.div`
  .header {
    position: fixed;
    z-index: 10;
    width: 100vw;
    padding: 0.5rem 15vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: none;
    transition: background-color 300ms;
    @media (max-width: 815px) {
      padding: 0.5rem 2rem;
    }
  }

  .solid {
    background-color: ${theme.colors.primary};
    transition: background-color 300ms;

    a {
      color: white;
      transition: color 300ms;
    }
    a:after {
      background-color: white;
    }
  }
  a {
    text-decoration: none;
    color: ${theme.colors.primary};
    transition: color 300ms;

    display: inline-block;
    position: relative;
  }

  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${theme.colors.primary};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .logo {
    font-size: 2rem;
  }

  .items {
    position: relative;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    width: 20rem;
    @media (max-width: 815px) {
      display: none;
    }
  }
`
