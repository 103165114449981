import { Text } from '@chakra-ui/react'
import { images } from '../theme'
import { Gallery } from '../types'

export const galleries: Gallery[] = [
  {
    header: 'ROPE ACCESS',
    images: [
      images.projects.rope['1'],
      images.projects.rope['2'],
      images.projects.rope['3'],
      images.projects.rope['4'],
      images.projects.rope['5'],
      images.projects.rope['6'],
      images.projects.rope['7'],
      images.projects.rope['8'],
      images.projects.rope['9'],
      images.projects.rope['10'],
    ],
    description: [
      [
        `We have been doing rope access painting since 2009, 
      the Beacon Isle in Plett being our first project.`,
      ],
      [
        ` Since 
      then we have used rope access painting in projects such as:`,
        `• Vista Bonita`,
        `• Oceans Two and La Palma in Mosselbay`,
        `• The Knysna Mall`,
        ` and many Residential buildings. `,
      ],
      [
        `In 2021 we 
          repainted the Beacon Isle Hotel for the second time.`,
      ],
    ],
  },
  {
    header: 'KNYSNA PROJECTS',
    images: [
      images.projects.knysna['1'],
      images.projects.knysna['2'],
      images.projects.knysna['3'],
      images.projects.knysna['4'],
      images.projects.knysna['5'],
      images.projects.knysna['6'],
      images.projects.knysna['7'],
      images.projects.knysna['8'],
      images.projects.knysna['9'],
      images.projects.knysna['10'],
    ],
    description: [
      [
        `Over nearly two decades, we have painted more than 400 residential buildings and more than 30 commercial properties in Knysna.`,
        `This includes private estates such as`,
        `• Simola`,
        `• Pezula Private Golf Estate`,
        `• Thesen Island`,
        `and all Eastford estates`,
      ],
      [
        ` Other well known buildings that we have painted in Knysna include `,
        `• Caltex Waterfront`,
        `• Village Square (Checkers)`,
        `• The Knysna Mall `,
        `• Woodmill Lane`,
        `as well as The Premier Hotel, The Riverclub, Quay West, The Beacon House, Summer Place and The Rex Hotel`,
      ],
    ],
  },
  {
    header: 'OTHER PROJECTS',
    images: [
      images.projects.other['1'],
      images.projects.other['2'],
      images.projects.other['3'],
      images.projects.other['4'],
      images.projects.other['5'],
      images.projects.other['6'],
      images.projects.other['7'],
      images.projects.other['8'],
      images.projects.other['9'],
      images.projects.other['10'],
    ],
    description: [
      [
        `We do residential buildings in Knysna and immediate surrounding areas, but for bigger projects we cover the whole of the Garden Route.`,
      ],
      [
        `• Keurbooms`,
        `• Plettenberg bay`,
        `• Sedgefield`,
        `• Wilderness`,
        `• George`,
        `• Glentana`,
        `• Hartenbos`,
        `• Mosselbay`,
      ],
    ],
  },
]
