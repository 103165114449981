import React from "react"
import { ApolloProvider } from "@apollo/client"
import { ChakraProvider } from "@chakra-ui/react"

import { Navigation } from "./components/Navigation"
import client from "./apollo"
import { theme, Fonts } from "./theme"

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <ApolloProvider client={client}>
        <Navigation />
      </ApolloProvider>
    </ChakraProvider>
  )
}

export default App
