import styled from 'styled-components'

interface Props {
  image: string
  direction: [string, string]
}

export const StyledImagePan = styled.div<Props>`
  --from: ${(Props) => Props.direction[0]}%;
  --to: ${(Props) => Props.direction[1]}%;

  position: relative;
  background-image: url(${(Props) => Props.image});
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  width: 100vw;
  z-index: inherit;

  @media (max-width: 815px) {
    height: 60vh;
    animation: pan 18s linear infinite alternate;
  }
  @media (min-width: 815px) {
    height: 60vh;
    animation: pan 13s linear infinite alternate;
  }
  @media (min-width: 1000px) {
    height: 70vh;
    animation: pan 13s linear infinite alternate;
  }

  @media (min-width: 1200px) {
    height: 80vh;
    animation: pan 10s linear infinite alternate;
  }

  @keyframes pan {
    from {
      background-position: var(--from) 0;
    }
    to {
      background-position: var(--to) 0;
    }
  }
`
