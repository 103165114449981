import team from '../assets/team.webp'
import fullTeam from '../assets/full-team.webp'
import fleet from '../assets/fleet.webp'

const images = {
  main: {
    team,
    fullTeam,
    fleet,
  },
  projects: {
    rope: {
      '1': require('../assets/images/rope-access/1.jpg'),
      '2': require('../assets/images/rope-access/2.jpg'),
      '3': require('../assets/images/rope-access/3.jpg'),
      '4': require('../assets/images/rope-access/4.jpg'),
      '5': require('../assets/images/rope-access/5.jpg'),
      '6': require('../assets/images/rope-access/6.jpg'),
      '7': require('../assets/images/rope-access/7.jpg'),
      '8': require('../assets/images/rope-access/8.jpg'),
      '9': require('../assets/images/rope-access/9.jpg'),
      '10': require('../assets/images/rope-access/10.jpg'),
    },
    knysna: {
      '1': require('../assets/images/knysna-projects/1.webp'),
      '2': require('../assets/images/knysna-projects/2.webp'),
      '3': require('../assets/images/knysna-projects/3.webp'),
      '4': require('../assets/images/knysna-projects/4.webp'),
      '5': require('../assets/images/knysna-projects/5.webp'),
      '6': require('../assets/images/knysna-projects/6.webp'),
      '7': require('../assets/images/knysna-projects/7.webp'),
      '8': require('../assets/images/knysna-projects/8.webp'),
      '9': require('../assets/images/knysna-projects/9.webp'),
      '10': require('../assets/images/knysna-projects/10.webp'),
    },
    other: {
      '1': require('../assets/images/other-projects/1.jpg'),
      '2': require('../assets/images/other-projects/2.jpg'),
      '3': require('../assets/images/other-projects/3.jpg'),
      '4': require('../assets/images/other-projects/4.jpg'),
      '5': require('../assets/images/other-projects/5.jpg'),
      '6': require('../assets/images/other-projects/6.jpg'),
      '7': require('../assets/images/other-projects/7.jpg'),
      '8': require('../assets/images/other-projects/8.jpg'),
      '9': require('../assets/images/other-projects/9.jpg'),
      '10': require('../assets/images/other-projects/10.jpg'),
    },
  },
}

export default images
