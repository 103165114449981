import { extendTheme, theme as chakraTheme } from "@chakra-ui/react"
import images from "./images"
import Fonts from "./fonts"

const theme = extendTheme({
  ...chakraTheme,
  fonts: {
    a: "Arial Black, sans-serif",
    heading: "Arial Black, sans-serif",
    // body: "Arial Black, sans-serif",
  },
  colors: {
    primary: "#2b5b76",
    secondary: "#3e8c83",
    yellow: "#f3e234",
    gray: "#515f65",
  },
})

export { theme, images, Fonts }
