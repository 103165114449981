import React, { ReactNode } from 'react'
import { ImageGallery } from './image-gallery/ImageGallery'

// import ReactMarkdown from 'react-markdown'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { Gallery } from '../types'
import { StyledMarquee } from './styled/StyledMarquee'

interface Props extends Gallery {
  header: string
  // description: string
  // description: string[]
  images: string[]
  variant: boolean
  imageObjects?: Image[]
}

export interface Image {
  src: string
  id: string
  alt: string
}

export const ProjectCard: React.FC<Props> = ({
  images,
  header,
  description,
  variant,
}) => {
  const isTabletOrMobile = window.innerWidth <= 815

  return (
    <Flex width="100%" alignItems="center" justifyContent="center">
      <Flex
        background={variant ? 'primary' : ''}
        boxShadow={variant ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : ''}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width={isTabletOrMobile ? '100%' : '80%'}
        padding="2% 0 2% 0"
        marginBottom="2%"
        borderRadius={isTabletOrMobile ? 50 : 20}
        flexWrap="wrap"
      >
        <Heading
          color={variant ? 'white' : 'primary'}
          fontSize="5vw"
          marginTop="2%"
          marginBottom="4%"
        >
          {header}
        </Heading>

        <Flex
          justifyContent={isTabletOrMobile ? 'center' : ''}
          alignItems="center"
          flexDirection={variant ? 'row-reverse' : 'row'}
          flexWrap={isTabletOrMobile ? 'wrap' : 'unset'}
          width="100%"
        >
          <Flex
            margin={isTabletOrMobile ? '' : variant ? '0 4% 2% 0' : '0 0 2% 4%'}
          >
            <ImageGallery images={images} size={isTabletOrMobile ? 70 : 120} />
          </Flex>
          <Flex
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              backgroundColor={variant ? 'whiteAlpha.200' : 'blackAlpha.100'}
              borderRadius={15}
              boxShadow={
                variant
                  ? '0px 0px 30px -9px rgba(247,247,247,0.14)'
                  : '5px 0px 30px 9px rgba(107,107,107,0.18)'
              }
              direction="column"
              padding={7}
              gap={10}
            >
              {description.map((paragraph) => (
                <Flex flexDir="column">
                  {paragraph.map((line) => (
                    <Text
                      color={variant ? 'white' : 'primary'}
                      fontSize={isTabletOrMobile ? 15 : 20}
                      width={isTabletOrMobile ? '100%' : 400}
                    >
                      {/* <ReactMarkdown></ReactMarkdown> */}
                      {line}
                    </Text>
                  ))}
                </Flex>
              ))}
            </Stack>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
