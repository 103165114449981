import React from 'react'
import { StyledFooter } from './styled/StyledFooter'
import { RiRoadMapLine as Location } from 'react-icons/ri'
import { RiPhoneLine as Phone } from 'react-icons/ri'
import { FiMail as Mail } from 'react-icons/fi'

export const Footer: React.FC = () => {
  const year = new Date().getFullYear()
  return (
    <StyledFooter>
      <div className="footer-container">
        <div className="copyright">
          <p>&copy; {year} TI Paint | All rights reserved</p>
        </div>
        <div className="contact">
          <div className="icon-text">
            <Location className="icon" color="white" />
            <p>6 Church Street, Knysna</p>
          </div>
          <div className="icon-text">
            <Phone className="icon" /> <p>044 382 6315</p>
          </div>
          <div className="icon-text">
            <Mail className="icon" /> <p>info@tipaint.co.za</p>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}
