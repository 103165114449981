import React from 'react'

import {
  StyledSummary,
  Header,
  MainBody,
  SmallBody,
  LeftBorder,
} from './styled/StyledSummary'

export const Summary: React.FC = () => {
  return (
    <StyledSummary>
      <MainBody>
        TI Paint is a family run business founded in 2003. We started as the
        sole provider of painting and building solutions for the then developing
        estate, Thesen Island. While TI Paint's main focus has become painting,
        we still offer excellent maintanance solutions.
      </MainBody>
      <div>
        <Header>WHAT WE DO</Header>
        <LeftBorder>
          <SmallBody>
            We specialise in residential-, commercial- and industrial painting.
            We have a team of rope access painters for high rise buildings. We
            use airless sprayers on all our roofs for a superior finish and
            speedy application. Our maintanance team are skilled in carpentry,
            tiliing, plastering and building.
          </SmallBody>
        </LeftBorder>
      </div>
    </StyledSummary>
  )
}
