import styled from "styled-components";

interface Props {
  image: string;
}

export const StyledFullImagePan = styled.div<Props>`
  background-image: url(${(Props) => Props.image});
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  background-attachment: fixed;
  width: 100vw;

  z-index: inherit;

  @media (max-width: 815px) {
    animation: pan2 60s linear infinite alternate;
  }
  @media (min-width: 815px) {
    animation: pan2 30s linear infinite alternate;
  }
  @media (min-width: 1000px) {
    animation: pan2 25s linear infinite alternate;
  }

  @media (min-width: 1200px) {
    animation: pan2 20s linear infinite alternate;
  }

  @keyframes pan2 {
    from {
      background-position: 0 100%;
    }
    to {
      background-position: 100% 0;
    }
  }
`;
