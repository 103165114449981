import React from 'react'
import { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link as RouterLink,
} from 'react-router-dom'
import {
  MenuButton,
  MobileNav,
  DesktopNav,
  Layout,
  CloseMenuDiv,
} from './styled/StyledNav'
import { GiHamburgerMenu as Menu } from 'react-icons/gi'

import { Home } from '../containers/Home'
// import { About } from "../containers/About";
// import { Services } from "../containers/Services";
// import { Projects } from "../containers/Projects";
import { ContactUs } from '../containers/ContactUs'
import { Footer } from './Footer'
import { Heading } from '@chakra-ui/react'

export const Navigation: React.FC = () => {
  const [solid, setSolid] = useState(false)
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => setSolid(window.pageYOffset > 10))
    }
  }, [])
  return (
    <Router>
      <Layout>
        <div>
          <CloseMenuDiv open={openNav} onClick={() => setOpenNav((c) => !c)} />
          <MobileNav open={openNav}>
            <Link onClick={() => setOpenNav((c) => !c)} to="/">
              Home
            </Link>
            {/* <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
            <Link to="/projects">Projects</Link> */}
            <Link onClick={() => setOpenNav((c) => !c)} to="/contact-us">
              Contact Us
            </Link>
          </MobileNav>
          <DesktopNav>
            <div className={`header ${solid ? 'solid' : ''}`}>
              <div className="logo">
                <Link to="/" fontSize={30}>
                  TI Paint
                </Link>
              </div>
              <div className="items">
                <Link to="/">Home</Link>
                {/* <Link to="/about">About</Link>
                <Link to="/services">Services</Link>
                <Link to="/projects">Projects</Link> */}
                <Link to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </DesktopNav>
          <MenuButton onClick={() => setOpenNav((c) => !c)}>
            <Menu
              className={`menu ${solid ? 'light' : ''} menu ${
                openNav ? 'light' : ''
              }`}
            />
          </MenuButton>

          <div>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              {/* <Route path="/about" element={<About />}></Route>
              <Route path="/services" element={<Services />}></Route>
              <Route path="/projects" element={<Projects />}></Route> */}
              <Route path="/contact-us" element={<ContactUs />}></Route>
            </Routes>
          </div>
          <Footer />
        </div>
      </Layout>
    </Router>
  )
}

type LinkProps = {
  to: string
  onClick?: () => void
  fontSize?: string | number
}
const Link: React.FC<LinkProps> = ({ onClick, to, fontSize, children }) => {
  return (
    <RouterLink to={to} onClick={onClick}>
      <Heading fontSize={fontSize}>{children}</Heading>
    </RouterLink>
  )
}
Link.defaultProps = {
  fontSize: 17,
}
