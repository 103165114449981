import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { InputControl, SubmitButton, TextareaControl } from 'formik-chakra-ui'
import { StyledRequestForm } from './styled/StyledRequestForm'
import { Flex, Heading, Spacer, Text, useToast } from '@chakra-ui/react'
import { RiRoadMapLine as Location } from 'react-icons/ri'
import { RiPhoneLine as Phone } from 'react-icons/ri'
import { FiMail as Mail } from 'react-icons/fi'
import axios from 'axios'

interface Values {
  fullName: string
  email: string
  phoneNumber: string
  message: string
}
interface Data {
  service_id: string
  template_id: string
  user_id: string
  template_params: Values
}
const serviceId = process.env.REACT_APP_SERVICE_ID || ''
const templateId = process.env.REACT_APP_TEMPLATE_ID || ''
const userId = process.env.REACT_APP_USER_ID || ''

export const ContactForm: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const toast = useToast()
  const initialValues = {
    fullName: '',
    email: '',
    phoneNumber: '',
    message: '',
  }
  const formData: Data = {
    service_id: serviceId,
    template_id: templateId,
    user_id: userId,
    template_params: initialValues,
  }

  const onSubmit = (values: Values, { resetForm }: any) => {
    formData.template_params = values
    try {
      setLoading(true)
      axios({
        method: 'post',
        url: 'https://api.emailjs.com/api/v1.0/email/send',
        data: formData,
      }).then(function (response) {
        resetForm()
        toast({
          description: 'Email successfully sent',
          duration: 6000,
          isClosable: true,
          title: 'Success',
          status: 'success',
          position: 'bottom-right',
        })
        setLoading(false)
      })
    } catch (err) {
      console.log('eror', err)
    }
  }

  const validationSchema = Yup.object({
    fullName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid Email Address').required('Required'),
    phoneNumber: Yup.string()
      .required('A phone number is required')
      .matches(/^0[0-9]{9}$/g, 'Please enter a valid  phone number'),
    message: Yup.string()
      .min(50, 'More details are always helpful.')
      .required('Required'),
  })

  return (
    <Flex
      width="100%"
      // alignItems="center"
      justifyContent="center"
      flexDirection={['column', 'row']}
      gap={5}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }: FormikProps<Values>) => (
          <Flex as="form" onSubmit={handleSubmit as any}>
            <StyledRequestForm>
              <Heading fontSize={30} color="primary">
                We'd love to hear from you!
              </Heading>
              <Spacer height={3} />
              <Text fontSize={18} color="gray">
                For any inquiries, questions or quotations please contact us or
                fill out the form below
              </Text>
              <Spacer height={3} />

              <InputControl
                name="fullName"
                placeholder="John Paul Jones"
                marginBottom="5%"
                label="Full Name"
                labelProps={{ color: 'secondary' }}
                textColor="primary"
              />
              <InputControl
                name="email"
                placeholder="john.pj@mail.com"
                marginBottom="5%"
                label="Email"
                labelProps={{ color: 'secondary' }}
                textColor="primary"
              />
              <InputControl
                name="phoneNumber"
                placeholder="073 094 9383"
                marginBottom="5%"
                label="Phone Number"
                labelProps={{ color: 'secondary' }}
                textColor="primary"
              />
              <TextareaControl
                id="message"
                name="message"
                placeholder="What would you like to enquire about?"
                textareaProps={{ minH: 150 }}
                label="Your message"
                labelProps={{ color: 'secondary' }}
                textColor="primary"
              />
              <SubmitButton
                mt={4}
                backgroundColor="secondary"
                color="white"
                type="submit"
                isLoading={loading}
              >
                Submit
              </SubmitButton>
            </StyledRequestForm>
          </Flex>
        )}
      </Formik>
      <StyledRequestForm width={20}>
        <Flex flexDirection="column" alignItems="center">
          <Location />
          <Text fontWeight="bold" color="primary" marginBottom={2}>
            6 Church Street, Knysna
          </Text>
          <Phone />
          <Text fontWeight="bold" color="primary" marginBottom={2}>
            044 382 6315
          </Text>
          <Mail />
          <Text fontWeight="bold" color="primary" marginBottom={2}>
            info@tipaint.co.za
          </Text>
        </Flex>
      </StyledRequestForm>
    </Flex>
  )
}
