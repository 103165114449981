import { Flex } from '@chakra-ui/react'
import React from 'react'

import { ContactForm } from '../components/ContactForm'
import { StyledFullImagePan } from '../components/styled/StyledFullImagePan'
import { images } from '../theme'

export const ContactUs: React.FC = () => {
  return (
    <StyledFullImagePan image={images.main.fullTeam}>
      <Flex minHeight="100vh" width="100vw" paddingTop={20} paddingBottom={20}>
        <ContactForm />
      </Flex>
    </StyledFullImagePan>
  )
}
