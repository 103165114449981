import styled from 'styled-components'
import { theme } from '../../theme'

export const StyledSummary = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Rajdhani:wght@700&display=swap');
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
  align-items: center;
  padding: 5vh 0 5vh 0;
`

export const Header = styled.div`
  padding-bottom: 1vh;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  width: 20vw;
  color: ${theme.colors.primary};

  @media (max-width: 815px) {
    font-size: 1.5rem;
    width: 60vw;
  }
  @media (min-width: 815px) and (max-width: 1215px) {
  }
`
export const MainBody = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  width: 30vw;
  color: ${theme.colors.gray};
  text-align: right;
  @media (max-width: 815px) {
    text-align: left;
    font-size: 1.2rem;
    width: 60vw;
    margin-bottom: 1vh;
  }
`
export const SmallBody = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  width: 30vw;
  color: ${theme.colors.secondary};
  @media (max-width: 815px) {
    font-size: 1.1rem;
    width: 60vw;
  }
`

export const LeftBorder = styled.div`
  border-left: 4px solid ${theme.colors.primary};
  padding-left: 2vw;
  @media (max-width: 815px) {
    padding-left: 4vw;
  }
`
