import { motion } from 'framer-motion'
import React from 'react'
import { StyledImage } from './StyledImage'

interface Props {
  selectedFirstImage: { src: string; index: number }
  selectedSecondImage: { src: string; index: number }
  displayHeight: string
  displayWidth: string
  showAnimation: boolean
}

export const Image: React.FC<Props> = ({
  selectedFirstImage,
  selectedSecondImage,
  displayHeight,
  displayWidth,
  showAnimation,
}) => {
  const firstVariants = {
    animate: {
      x: showAnimation ? '100px' : 0,
      opacity: showAnimation ? 0 : 1,
    },

    transition: { duration: 0.5 },
  }
  const secondVariants = {
    animate: {
      x: showAnimation ? 0 : '100px',
      opacity: showAnimation ? 1 : 0,
    },
    transition: { duration: 0.5 },
  }

  return (
    <div style={{ width: `${displayWidth}` }}>
      <motion.div {...firstVariants}>
        <StyledImage
          style={{ position: 'absolute' }}
          src={selectedFirstImage.src}
          height={displayHeight}
          width={displayWidth}
          main
        />
      </motion.div>
      <motion.div {...secondVariants}>
        <StyledImage
          style={{ position: 'absolute' }}
          src={selectedSecondImage.src}
          height={displayHeight}
          width={displayWidth}
          main
        />
      </motion.div>
    </div>
  )
}
